import React from 'react';
import OAuth from './OAuth.js'
import Header from './Header.js'

function Settings() {

  return (
    <>
    <div>
      <h1>Settings</h1>
      <OAuth/>
    </div>
    </>
  );
}

export default Settings;