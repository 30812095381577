import React from 'react';

export function InfoBox({ title, dataPoints }) {
  return (
    <div className="info-box">
      <h4>{title}</h4>
      {dataPoints.map((point, index) => (
        <p key={index}>
          {point.label}: <span>{point.value}</span>
        </p>
      ))}
    </div>
  );
}

const InfoGrid = ({ headers, rows }) => (
  <table>
    <thead>
      <tr>
        {headers.map(header => <th key={header}>{header}</th>)}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, index) => (
        <tr key={index}>
          {row.map((cell, cellIndex) => <td key={cellIndex}>{cell}</td>)}
        </tr>
      ))}
    </tbody>
  </table>
);

