import React, { useEffect, useState, createContext, useContext } from 'react';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils'
import {
  Authenticator,
  ThemeProvider,
  Theme,
  Image,
  useTheme,
  View,
  useAuthenticator
} from '@aws-amplify/ui-react';
import { fetchAuthSession, fetchUserAttributes, signOut as amplifySignOut } from '@aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import Main from './Main.js';
import Callback from './Callback.js'
import Settings from './Settings.js'
import Header from './Header.js'
import colorLogo from './Color_logo.png'
import { useAuthTheme } from './AuthTheme.js'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserProvider } from './UserContext';
import LoadUser from './LoadUser.js'
import ParameterGuide from './ParameterGuide.js'



Amplify.configure(config);
const UserContext = createContext();
export const useUserData = () => useContext(UserContext);


function App() {

  const [isUserSignedIn, setIsUserSignedIn] = useState(false);
  

  
  console.log ("User signed in:", isUserSignedIn)
  useEffect(() => {
    
    const checkUserSession = async () => {
        try {
            await fetchAuthSession();
            console.log('fetchAuthSession: User is currently signed in');
            setIsUserSignedIn(true);
        } catch (error) {
            console.log('fetchAuthSession: No user signed in');
            setIsUserSignedIn(false);
        }
    }; checkUserSession();
    
    // Define the listener within useEffect
  const authListener = (data) => {
      console.log('Auth event received: ', data); // Log every auth event
      
      switch (data.payload.event) {
        case 'signedIn':
        case 'tokenRefresh':
          console.log('User signed in');
          setIsUserSignedIn(true);
          break;
        case 'signedOut':
          console.log('User signed out');
          setIsUserSignedIn(false);
          break;
        // Handle other events as needed
        default:
          console.log('Unhandled auth event', data.payload.event);
          break;
      }
    };
    
    // Listen for auth events and store the return value to use for cleanup
  const hubListenerCancelToken = Hub.listen('auth', authListener);

    // Clean-up function to stop listening
    return () => {
      // Use the cancellation token to remove the listener
      console.log ("Removing auth listener")
      hubListenerCancelToken();
    };
  }, []); // Empty dependency array ensures this runs only on component mount and unmount


  // Apply conditional styles or classes based on `isUserSignedIn`
  const containerClass = isUserSignedIn ? '' : 'auth-screen'; // Example class name
  const authTheme = useAuthTheme();


const AuthHeader = () => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image
        alt="HabitaIO"
        src={colorLogo}
      />
    </View>
  );
};



  
//              <Header onSignOut={signOut} onSettings={toggleSettings}/>


  
return (
  <ThemeProvider theme={authTheme}>
    <div className={`app-container ${containerClass}`}>
      <Authenticator components={{ Header: AuthHeader }} hideSignUp={true}>
        {({ signOut, user }) => (

          <UserProvider>
            <BrowserRouter>
              <LoadUser />
              <Header/>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/callback" element={<Callback />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/ParameterGuide" element={<ParameterGuide/>} />
              </Routes>
            </BrowserRouter>
          </UserProvider>

        )}
      </Authenticator>
    </div>
  </ThemeProvider>
);
}

export default App;