import React from 'react';

const ParameterGuide = () => {
  return (
    <div>
      <h1>Instructions</h1>
      <p>Here you can add text, images, videos, and other media...</p>
    </div>
  );
};

export default ParameterGuide;