import React from 'react';
import './App.css'; 
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns'
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import  VentilationStatus  from './VentilationStatus.js';

const VentilationCharts = ({ currentDate, onPreviousDate, onNextDate, ventilationData, ventIds }) => {

  // Extract metadata for the given ventilation ID
  console.log ("Starting rendring VentilationCharts:", ventIds)
  console.log("CurrentDate:", currentDate)
    
  //const statusSummary = ventilationData.status_summary;  
  
  
  
  
    // Function to check if the provided date is before today
  const isBeforeToday = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset hours, minutes, seconds, and ms for an accurate comparison
    return date < today;
  };  
  
//    <h3>History</h3>    
  
    return (
   <>
     <div className="center-header">
        <h2>Status - {ventilationData.metadata.last_analysed}</h2>
    </div>  
    <VentilationStatus data={ventilationData} ventIds={ventIds} />
    
    <div className="center-header">
      
      <button onClick={onPreviousDate}><MdArrowBack /></button>
      <h2>History - {ventilationData.metadata.date}</h2>
      
      {isBeforeToday(currentDate) && <button onClick={onNextDate}><MdArrowForward /></button>}
      
    </div>

    
    {ventIds.map((ventId, index) => ( // Ensure ventId is unique or use an index for the key
      <React.Fragment key={ventId || index}> {/* Wrap sibling elements in a React Fragment with a unique key */}
        <VentilationPowerChart ventilationData={ventilationData} ventId={ventId} />

      </React.Fragment>
    ))}
  </>
    );
};


const VentilationPowerChart = ({ ventilationData, ventId }) => {
  const ventData = ventilationData[ventId];
  const ventMetadata = ventilationData[ventId]?.metadata;
  const parameters = ventMetadata ? Object.keys(ventMetadata.parameters) : []; // Default to an empty array if no parameters are set  
  
  if (!ventData) {
    console.error(`No data found for ventId: ${ventId}`);
    return null; // Or some loading/error indicator
  }

const chartData = Object.entries(ventData)
  .filter(([key]) => key !== 'metadata')
  .map(([timestamp, data]) => ({
    timestamp,
    ventilation_value: data.ventilation_value,
    ventilation_state: data.ventilation_state  // Include the ventilation_state here
  }));

  const ventName = ventilationData[ventId]['metadata']?.name || 'Unknown';
  const status = ventilationData[ventId]['metadata']?.status || 'Unknown';
  const timeRange = ventilationData[ventId]['metadata']?.time_range || 'Unknown';
  const timeOnStates = ventilationData[ventId]['metadata']?.time_on_states || {};
  
 console.log(ventId);
 console.log(status);
  
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    // Assuming the relevant data is directly accessible in the payload item
    return (
      <div className="custom-tooltip">
        <p className="label">{payload[0].payload.timestamp}</p>
        {/* Access the 'ventilation_state' directly from the payload */}
        <p className="intro">State: {payload[0].payload.ventilation_state}</p>
      </div>
    );
  }

  return null;
};





  return (
    <div>
      <p><strong>Power Chart: {ventName}</strong></p>
      <p>Time on
        {Object.entries(timeOnStates).map(([state, time]) => (
          <li key={state}>{state}: {time}</li>
        ))}
      </p>
      <ResponsiveContainer width="100%" height={150}>
        <LineChart 
          data={chartData}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
          //syncId={ventId}
          >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
          dataKey="timestamp"
          tickFormatter={(tickItem) => format(new Date(tickItem), 'HH')}
          interval={3} // Adjust according to your data, interval skips ticks
          />
           <YAxis domain={['auto', 'auto']} />
          <Tooltip content={<CustomTooltip />} />
          <Line type="step" dataKey="ventilation_value" stroke="#0047AB" yAxisId={0} strokeWidth={2} dot={false}/>
        </LineChart>
      </ResponsiveContainer>
      {parameters.map(parameter => (
          <ParameterChart
            key={parameter}
            ventilationData={ventilationData}
            ventId={ventId}
            parameter={parameter}
          />
        ))}
    </div>
  );
};


const ParameterChart = ({ ventilationData, ventId, parameter }) => {
  const ventData = ventilationData[ventId];
  
  // Convert ventilation data to chart format
  const chartData = Object.entries(ventData)
    .filter(([key]) => key !== 'metadata' && key !== 'thresholds') // Exclude 'ventName' and 'thresholds'
    .map(([timestamp, data]) => ({
      timestamp,
      ...data[parameter] // Spread operator to include all device data for the parameter
    }));

  const metadata = ventilationData[ventId]?.metadata;
  // extract devices from metadata
  const deviceNames = metadata?.parameters[parameter] || [];
  // Extract units mapping from metadata
  const units = metadata?.units;

  // Find the unit for the current parameter
  const unit = units?.[parameter]; 
  
const CustomTooltipParameters = ({ active, payload }) => {
  if (!active || !payload || payload.length === 0) return null;

  const formatValue = (value) => {
    if (value === undefined) return 'N/A'; // Handle undefined values
    if (typeof value === 'object' && value !== null) {
      return 'N/A'; // Handle object types, assuming they're not desired for direct display
    }
    return value; // Return value as is if not an object
  };

  // Choose one entry from payload for mapping the data, let's assume the first one
  const dataEntries = Object.entries(payload[0].payload);

  return (
    <div className="custom-tooltip">
      {dataEntries.map(([key, value], index) => {
        // Find the entry in payload that matches the current key (if color is key-specific)
        const matchingEntry = payload.find(p => p.dataKey === key);
        const color = matchingEntry ? matchingEntry.color : '#000'; // Default to black if no match
        return (
          <p key={key} style={{ color }}>
            {`${key}: ${formatValue(value)}`}
          </p>
        );
      })}
    </div>
  );
};



  
 const deviceColors = [
  '#8884d8', // Purple
  '#82ca9d', // Green
  '#ffc658', // Yellow
  '#ff7300', // Orange
  '#f45b5b', // Red
  '#00c49f', // Teal
  '#a4de6c', // Light green
  '#9053c7'  // Orchid
  // Add more colors as needed
  ];
  
    // This object will store the device name-color mapping
  //let deviceColorMapping = {};
  
  // Function to assign a color to a device based on its name
  const assignColorToDevice = (deviceName) => {
    // Compute a hash code of the device name to get a consistent color index
    let hash = 0;
    for (let i = 0; i < deviceName.length; i++) {
      hash = deviceName.charCodeAt(i) + ((hash << 5) - hash);
    }
    
    // Use the hash code to get an index for the color array
    const index = Math.abs(hash) % deviceColors.length;
    return deviceColors[index];
  };
  

  
  return (
    <div>
    <p><strong>{parameter.toUpperCase()} {unit ? `(${unit})` : ''}</strong></p>
    <ResponsiveContainer width="100%" height={150}>
      <LineChart
        width={500}
        height={300}
        data={chartData}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
        //syncId={ventId}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timestamp"
          tickFormatter={(tickItem) => format(new Date(tickItem), 'HH')}
          interval={3} // Adjust according to your data, interval skips ticks
        />
        <YAxis domain={['auto', 'auto']} />
        <Tooltip content={<CustomTooltipParameters />} />
       
        
        {deviceNames.map(deviceName => {
        // Plotting device data
        if (deviceName !== 'th_normal' && deviceName !== 'th_reduced') {
          const color = assignColorToDevice(deviceName);
          return (
            <Line
              type="monotone"
              dataKey={deviceName}
              key={deviceName}
              stroke={color} // Use assigned color
              dot={false}
            />
          );
        }
        // Plotting threshold lines with a dashed style
       {/* Other components for heating, etc. 
        else if (deviceName === 'Threshold: normal' || deviceName === 'th_reduced') {
          return (
            <Line
              type="stepBefore"
              dataKey={deviceName}
              key={deviceName}
              stroke={deviceName === 'th_normal' ? "#008000" : "#FFA500"} // //"#82ca9d" : "#d884d8"Example: green for normal, purple for reduced
              strokeDasharray="5 5"// This creates the dashed line effect
              strokeWidth={3}
            />
          );
        } */}
        return null;
      })}
              // Explicitly render threshold lines
        <Line
          type="stepBefore"
          dataKey="Threshold, max"
          key="Threshold, max"
          stroke="#008000" // Green for normal threshold
          strokeDasharray="5 5" // Dashed line effect
          strokeWidth={1}
          dot={false}
        />
        <Line
          type="stepBefore"
          dataKey="Threshold, min"
          key="Threshold, min"
          stroke="#FFA500" // Orange for reduced threshold
          strokeDasharray="5 5" // Dashed line effect
          strokeWidth={1}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
    </div>
  );
};




export default VentilationCharts;