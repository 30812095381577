import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const COLORS = [
  "#8884d8", "#82ca9d", "#ffc658", "#ff8042",
  "#a4de6c", "#d0ed57", "#8dd1e1", "#83a6ed",
  "#ffbb28", "#ff7300", "#00c49f", "#ff9999"
];

const PieCharts = ({ thermostatDetails }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Validate the data and provide defaults if necessary
  if (!thermostatDetails || Object.keys(thermostatDetails).length === 0) {
      return <div>No data available for thermostat details.</div>;
  }

  const thermostatCount = Object.keys(thermostatDetails || {}).length;

  const dynamicChartWidth = isMobile
    ? 400 // for mobile
    : 600;
  
  const dynamicChartHeight = isMobile && thermostatCount > 10
    ? 500
    : 450;
    
  
  const dynamicOuterRadius = isMobile
    ? 100
    : 140;


  //const legendRows = Math.ceil(thermostatCount / 5); // Adjust based on items per row
  //const dynamicChartHeight = 500 + legendRows * 20; // Base height + space for legend rows


  //const dynamicBottomMargin = thermostatCount > 10 ? 80 : 40;
  const dynamicLegendLayout = isMobile ? "horizontal" : "vertical";
  const dynamicAlign = isMobile ? "center" : "right";
  const dynamicVerticalAlign = isMobile ? "bottom" : "middle";
  
  const dynamicLegendWrapperStyle = {
    marginTop: 10, //thermostatCount > 10 ? -10 : 10,
    whiteSpace: "normal",
    maxWidth: "100%",
  };
  console.log ("ChartSize:", dynamicChartWidth, dynamicChartHeight)
  console.log ("Radius", dynamicOuterRadius)
  
  const pieData1 = Object.keys(thermostatDetails || {}).map((key) => ({
    name: thermostatDetails[key]?.name || "Unknown",
    value: thermostatDetails[key]?.total_consumption || 0,
  }));

  const pieData2 = Object.keys(thermostatDetails || {}).map((key) => ({
    name: thermostatDetails[key]?.name || "Unknown",
    value: thermostatDetails[key]?.total_cost || 0,
  }));




  // Custom legend formatter
  const renderLegend = (value, entry) => {
    const { payload } = entry;
    return `${payload.name}: ${payload.value.toFixed(2)}`;
  };

  // Handle case where filtered data might be empty
  if (pieData1.length === 0 || pieData2.length === 0) {
    return <div>No valid data available to render thermostat pie charts.</div>;
  }


  return (
    <div className="pie-chart-container">
      <div className="pie-chart">
        <h4>Total Consumption by Thermostat (kWh)</h4>
        <PieChart
          width={dynamicChartWidth}
          height={dynamicChartHeight}
          margin={{
            top: 10,
            bottom: 10, // Dynamic bottom margin for legend
          }}

        >
          <Pie
            data={pieData1}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={dynamicOuterRadius}
            fill="#8884d8"
          >
            {pieData1.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend
            layout={dynamicLegendLayout}
            verticalAlign={dynamicVerticalAlign}
            align={dynamicAlign}
            wrapperStyle={dynamicLegendWrapperStyle}
            formatter={renderLegend}
          />
          <Tooltip />
        </PieChart>
      </div>
      <div className="pie-chart">
        <h4>Total Cost by Thermostat (cents)</h4>
        <PieChart width={dynamicChartWidth} height={dynamicChartHeight}>
          <Pie
            data={pieData2}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={dynamicOuterRadius}
            fill="#82ca9d"
          >
            {pieData2.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend
            layout={dynamicLegendLayout}
            verticalAlign={dynamicVerticalAlign}
            align={dynamicAlign}
            wrapperStyle={dynamicLegendWrapperStyle}
            formatter={renderLegend}
          />
          <Tooltip />
        </PieChart>
      </div>
    </div>
  );
};


export default PieCharts;
