import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from './UserContext';


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Callback() {
  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');
  const { user, userData, session, jwtToken, loadingUser, showSettings } = useUser();
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Account name:", userData?.accountName)
    const processOAuth = async () => {
      if (!userData) {
        console.error("No user data available");
        return;
      }
      
      //const session = await fetchAuthSession();
      //const jwtToken = session.tokens.idToken.toString();
      //const jwtToken = user.signInUserSession.idToken.jwtToken;
     const accountName = userData?.accountName; //user.signInDetails.loginId; // or other identifier from user attributes

      //console.log("Processing OAuth with code:", code, "and state:", state, "for user:", accountName);

      try {
        const response = await fetch('https://j1jjasmb97.execute-api.eu-north-1.amazonaws.com/ProcessOauth-API', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwtToken}`
          },
          body: JSON.stringify({ code, state, accountName }),
        });
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorData.message}`);
        }
        console.log('OAuth processing successful:', response.status);
        setSuccessMessage('OAuth processing was successful!');
        navigate('/settings');
      } catch (error) {
        console.error('Failed to process OAuth:', error);
      }
    };

    if (code && state) {
      processOAuth();
    }
  }, [code, state, user]);

  return (
    <div>
      <h1>Processing OAuth callback...</h1>
    </div>
  );
}

export default Callback;