import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function OAuth() {
  //const navigate = useNavigate();

  const handleOAuthLogin = () => {
    console.log ('Starting OAUTH')
    const clientId = 'shelly-diy';
    const redirectUri = `${window.location.origin}/callback`;
    //const scope = 'email';
    const responseType = 'code';
    const state = 'sh_982149'
    const authUrl = `https://my.shelly.cloud/oauth_login.html?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&state=${state}`;
    console.log ("State:", state)
    // Redirect to the OAuth provider's URL
    window.location.href = authUrl;
  };
  
  return (
    <div>
      <h2> 3rd Party Service Provider Authorizations </h2>
      <p> <strong> Shelly </strong> <button onClick={handleOAuthLogin}>Authenticate</button></p>
    </div>
  );
}

export default OAuth;