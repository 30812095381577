const VentilationStatus = ({ data, ventIds }) => {
  if (!ventIds || ventIds.length === 0) {
    return <div>No data available.</div>;
  }

  const headers = ["Air control name", "State", "Since", "Triggered by", "Threshold Range"];

  // Prepare rows for each ventilation ID
  const rows = ventIds.map(ventId => {
    const status = data[ventId]?.metadata?.status;
    if (!status) {
      console.log("Status data missing for ventId:", ventId);
      return [ventId, "Unknown", "N/A", "N/A", "N/A"]; // Safe default values
    }

    const { ventilation_name, state, timestamp, triggers, threshold_name } = status;
    return [
      ventilation_name || "Unknown",
      state || "N/A",
      new Date(timestamp).toLocaleString() || "N/A",
      triggers?.join(', ') || 'No triggers',
      threshold_name || 'N/A'
    ];
  }).filter(row => row.length > 0); // Only include non-empty rows
  
const InfoGrid = ({ headers, rows }) => (
  <table className="info-grid">
    <thead>
      <tr>
        {headers && headers.map(header => <th key={header}>{header}</th>)}
      </tr>
    </thead>
    <tbody>
      {rows && rows.map((row, index) => (
        <tr key={index}>
          {row.map((cell, cellIndex) => <td key={cellIndex}>{cell}</td>)}
        </tr>
      ))}
    </tbody>
  </table>
);
  

  return (
    <div>
      <InfoGrid
        headers={headers}
        rows={rows}
      />
    </div>
  );
  
  
  
  
};

export default VentilationStatus;