import React, { useState } from 'react';
//import { useUser } from './UserContext';
import { RoomTable, BasicInfo, ProfileTable, TimeBasedAdjustments } from './HeatingSettings_Components.js'



const HeatingSettings = ({ initialHeatingConfigs, onSave, saveStatus }) => {
  const [heatingConfig, setHeatingConfig] = useState(initialHeatingConfigs);
 
  //console.log("Save status:", saveStatus)
  console.log ("Initial HeatingCOnfigs",heatingConfig)
  if (!heatingConfig || Object.keys(heatingConfig).length === 0) {
    return <div>Loading configuration data...</div>;
  }

  const handleSchemeChange = (newActiveScheme) => {
    setHeatingConfig((prevConfig) => ({
      ...prevConfig,
      basic_configs: {
        ...prevConfig.basic_configs,
        active: newActiveScheme,
      },
    }));
  };

const handleToggleEnabled = (profileId, field, isEnabled) => {
  console.log ("Handling toggle", profileId, field, isEnabled)
  setHeatingConfig((prevConfig) => {
    const updatedProfiles = prevConfig.profile_configs.map((profile) => {
      if (profile.id === profileId) {
        return {
          ...profile,
          [field]: isEnabled,
        };
      }
      return profile;
    });
    return { ...prevConfig, profile_configs: updatedProfiles };
  });
};


const handlePriceChange = (profileId, rangeId, scheme, value) => {
  const parsedValue = value === '' ? 0 : parseFloat(value);  // Handle empty strings

  setHeatingConfig((prevConfig) => {
    const updatedProfiles = prevConfig.profile_configs.map((profile) => {
      if (profile.id === profileId) {
        const updatedPriceRanges = profile.price_ranges.map((range) => {
          if (range.id === rangeId) {
            return {
              ...range,
              [scheme]: parsedValue, // Ensure value is parsed as a number
            };
          }
          return range;
        });
        return { ...profile, price_ranges: updatedPriceRanges };
      }
      return profile;
    });

    return { ...prevConfig, profile_configs: updatedProfiles };
  });
};

const handleReserveChange = (profileId, field, value) => {
  const parsedValue = value === '' ? 0 : parseFloat(value);  // Handle empty strings

  setHeatingConfig((prevConfig) => {
    const updatedProfiles = prevConfig.profile_configs.map((profile) => {
      if (profile.id === profileId) {
        return {
          ...profile,
          reserve_heating: {
            ...profile.reserve_heating,
            [field]: parsedValue, // Ensure value is parsed as a number
          },
        };
      }
      return profile;
    });

    return { ...prevConfig, profile_configs: updatedProfiles };
  });
};

const handlePriceDropChange = (profileId, scheme, newValue) => {
  const parsedValue = newValue === '' ? 0 : parseFloat(newValue);  // Handle empty strings

  setHeatingConfig(prevConfig => ({
    ...prevConfig,
    profile_configs: prevConfig.profile_configs.map(profile => 
      profile.id === profileId
        ? {
            ...profile,
            price_based_drops: {
              ...profile.price_based_drops,
              [scheme]: parsedValue // Ensure value is parsed as a number
            }
          }
        : profile
    )
  }));
};

const handleThermostatSettingChange = (profileId, settingKey, newValue) => {
  const parsedValue = newValue === '' ? 0 : parseFloat(newValue);  // Handle empty strings
  setHeatingConfig((prevConfig) => ({
    ...prevConfig,
    profile_configs: prevConfig.profile_configs.map((profile) =>
      profile.id === profileId
        ? {
            ...profile,
            thermostat_settings: {
              ...profile.thermostat_settings,
              [settingKey]: {
                ...profile.thermostat_settings[settingKey],
                value: parsedValue,
              },
            },
          }
        : profile
    ),
  }));
};


const handleNestedChange = (profileId, settingCategory, thermostatKey, settingKey, newValue) => {
  const parsedValue = newValue === '' || isNaN(newValue) ? 0 : parseFloat(newValue);

  setHeatingConfig((prevConfig) => ({
    ...prevConfig,
    profile_configs: prevConfig.profile_configs.map((profile) => {
      if (profile.id === profileId) {
        return {
          ...profile,
          [settingCategory]: {
            ...profile[settingCategory],
            [thermostatKey]: {
              ...profile[settingCategory][thermostatKey],  // Keep other fields of the thermostat
              [settingKey]: parsedValue,  // Update only the specific field (limit, hours, reserve_degrees)
            },
          },
        };
      }
      return profile;
    }),
  }));
};

const handleGroupLevelChange = (profileId, settingCategory, settingKey, newValue) => {
  const parsedValue = newValue === '' || isNaN(newValue) ? 0 : parseFloat(newValue);

  setHeatingConfig((prevConfig) => ({
    ...prevConfig,
    profile_configs: prevConfig.profile_configs.map((profile) => {
      if (profile.id === profileId) {
        return {
          ...profile,
          [settingCategory]: {
            ...profile[settingCategory],
            [settingKey]: parsedValue,  // Only update the specific key (hours or reserve_degrees)
          },
        };
      }
      return profile;
    }),
  }));
};

const handleUpdateAdjustment = (id, updatedAdjustment) => {
  console.log("Updating Adjustment:", id, updatedAdjustment);

  setHeatingConfig((prevConfigs) => {
    // Create a deep copy of the basic_configs object to ensure React notices the change
    const updatedBasicConfigs = {
      ...prevConfigs.basic_configs,
      time_based_adjustments: {
        ...prevConfigs.basic_configs.time_based_adjustments,
        [id]: updatedAdjustment, // Update the specific adjustment
      },
    };

    return {
      ...prevConfigs,
      basic_configs: updatedBasicConfigs, // Replace basic_configs with the updated copy
    };
  });
};

const handleTimeBasedAdjustmentToggle = (profileId, fieldPath, value) => {
  setHeatingConfig((prevConfig) => {
    const updatedProfiles = prevConfig.profile_configs.map((profile) => {
      if (profile.id === profileId) {
        const fieldParts = fieldPath.split('.');
        const adjustmentId = fieldParts[1]; // Extract adjustment ID
        const schemeKey = fieldParts[2]; // Extract scheme key

        // Clone the adjustments to avoid mutation
        const updatedTimeBasedAdjustments = { ...profile.time_based_adjustments };

        // Ensure the specific adjustment exists
        if (updatedTimeBasedAdjustments[adjustmentId]) {
          updatedTimeBasedAdjustments[adjustmentId] = {
            ...updatedTimeBasedAdjustments[adjustmentId],
            [schemeKey]: value, // Update the specific scheme key
          };
        }

        return {
          ...profile,
          time_based_adjustments: updatedTimeBasedAdjustments,
        };
      }
      return profile;
    });

    return { ...prevConfig, profile_configs: updatedProfiles };
  });
};


  const handleSave = () => {
    // Send the updated heatingConfig to the parent component
    onSave(heatingConfig);
  };

  return (
    <div className="container">
      <h2>Heating Settings</h2>
      
      <BasicInfo
        basicInfo={heatingConfig.basic_configs}
        onSchemeChange={handleSchemeChange}
      />
      
      <RoomTable
        roomData={heatingConfig.room_configs}
      />

      <TimeBasedAdjustments
        basicInfo={heatingConfig.basic_configs}
        onUpdateAdjustment={handleUpdateAdjustment}
      />
      
      <ProfileTable 
        profileConfigs={heatingConfig.profile_configs}
        basicInfo={heatingConfig.basic_configs}
        onTimeBasedAdjustmentToggle={handleTimeBasedAdjustmentToggle}
        onToggleEnabled={handleToggleEnabled}
        onToggleOptimisationEnabled={handleToggleEnabled}
        onPriceChange={handlePriceChange}
        onReserveChange={handleReserveChange}
        onReserveHeatingAfternoonChange={handleGroupLevelChange}
        onPriceDropChange={handlePriceDropChange}
        onThermostatSettingChange={handleThermostatSettingChange}
        //onThermostatSettingChange={handleNestedChange}
        onThermostatReserveHeatingChange={handleNestedChange}
      />
      <p></p>
      {saveStatus === 'success' && <p><i>Configurations updated successfully!</i></p>}
      {saveStatus === 'error' && <p>Error updating configurations. Please try again.</p>}
      <button onClick={handleSave}>Save Settings</button>
    </div>
  );
};

export default HeatingSettings;