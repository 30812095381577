import React, { createContext, useState, useContext } from 'react';


const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [session, setSession] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [showSettings, setShowSettings] = useState(false);

  return (
    <UserContext.Provider value={{ user, setUser, userData, setUserData, session, setSession, jwtToken, setJwtToken, loadingUser, setLoadingUser, showSettings, setShowSettings }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);