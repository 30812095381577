import React from 'react';
//import { Amplify } from 'aws-amplify';
import logo from './Color_logo.png';
//import { getCurrentUser } from 'aws-amplify/auth';
import { MdLogout } from "react-icons/md";
import { MdSettingsSuggest } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import { signOut as amplifySignOut } from '@aws-amplify/auth';


function Header() {
  const { userData, showSettings, setShowSettings } = useUser();
  const accountName = userData?.accountName;
  const name = userData?.name;
  const address = userData?.address;
  const email = userData?.email;
  
  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const signOut = async () => {
    try {
      await amplifySignOut();
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  //console.log ("accountName from Header: ", accountName){userData}
    
  return (
  <div className="App">
    <header className="App-header">
      <div className="left-content">
        <Link to="/">
                <img src={logo} className="App-logo" alt="logo" />
        </Link>
        <div className="text-container">
          <p>My HabitaIO </p>
          <p>{name}, {address}, {email}</p>
        </div>
      </div>      
      <div className="right-content">
        <button className="settings" onClick={toggleSettings}><MdSettingsSuggest /></button>
        <button className="sign-out" onClick={signOut}><MdLogout /></button>
      </div>
    </header>
  </div>
  );
}

export default Header;