import React, { useEffect } from 'react';
import { useUser } from './UserContext'; // Import the custom hook for accessing the context
import { fetchAuthSession, fetchUserAttributes } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';

function LoadUser() {
  const { setUser, setUserData, setSession, setJwtToken, setLoadingUser } = useUser(); // Use the custom hook here
  const { user } = useAuthenticator();

  useEffect(() => {
    console.log("Fetching user details")
    async function fetchUserData() {
      if (user) {
        try {
          const session = await fetchAuthSession();
          const jwtToken = session.tokens.idToken.toString();
          const userAttributes = await fetchUserAttributes();
          const userData = {
            accountName: user.signInDetails.loginId,  //'jouni.vikman@phnet.fi', //'timo_nieminen10@hotmail.com', //user.signInDetails.loginId, 'antti@habitaio.com',
            name: userAttributes.name,
            address: userAttributes.address,
            email: userAttributes.email
          };
          setUser(user)
          setUserData(userData);
          setSession(session);
          setJwtToken(jwtToken);
          setLoadingUser(false);
          
          console.log("User details fetched")
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    }

    fetchUserData();
  }, [user, setUserData, setSession, setJwtToken]);

  return null; // This component does not render anything
}

export default LoadUser;
