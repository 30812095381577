import './HeatingSettings.css';
import NumericInput from './NumericInput.js'
import React, { useState } from 'react';

export function RoomTable ({ roomData }) {
    return (
    <div>
      <p></p>
      <h3>Rooms, devices and and temperature profiles</h3>
     <table className="room_table">
      <thead>
        <tr>
          <th>Room</th>
          <th>Temperature profile</th>
          <th>Thermometer</th>
          <th>Heaters</th>
        </tr>
      </thead>
      <tbody>
        {roomData.map((room, index) => (
          <tr key={index}>
            <td>{room.name}</td>
            <td>{room.profile}</td>
            <td>{room.thermometer}</td>
            <td>
              {room.heaters.map((heater, idx) => (
                <div key={idx}>{heater}</div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export function BasicInfo({ basicInfo, onSchemeChange, onUpdateAdjustment }) {
  
  return (
    <div>
      <h3>Basic Settings</h3>
      <h4>Choose Scheme</h4>
      {Object.entries(basicInfo.schemes)
        .filter(([key]) => key !== "active" && key !== "time_based_adjustments")
        .map(([key, value]) => (
          <span key={key} className="radio-option">
            <label>
              <input
                type="radio"
                value={key}
                checked={basicInfo.schemes.active === key}
                onChange={() => onSchemeChange(key)}
              />
              {value}
            </label>
          </span>
        ))}
    </div>
  );
}


export function TimeBasedAdjustments({ basicInfo, onUpdateAdjustment }) {
  
  const validateAdjustment = (adjustment) => {
    const { value, start_at, end_at, active_days } = adjustment;

    // Check if value is non-zero
    if (value === 0 ) {
      return { isValid: false, error: "Value set as 0" };
    }  
  
    // Check if start_at and end_at are valid
    if (start_at === undefined || end_at === undefined) {
      return { isValid: false, error: "Start or End time is missing" };
    }
    if (start_at < 0 || start_at > 23 || end_at < 0 || end_at > 23) {
      return { isValid: false, error: "Start/End time must be between 0 and 23" };
    }
  
    // Check if active_days is a valid object
    if (!active_days || typeof active_days !== "object") {
      return { isValid: false, error: "Active days configuration is missing or invalid" };
    }
  
    // Check if at least one day is active
    const hasActiveDay = Object.values(active_days).some((isActive) => isActive);
    if (!hasActiveDay) {
      return { isValid: false, error: "At least one active day must be selected" };
    }
  
    // All validations passed
    return { isValid: true, error: null };
  };
  
  

  const handleFieldChange = (id, field, value) => {
    const adjustment = basicInfo.time_based_adjustments[id];
    const updatedAdjustment = {
      ...adjustment,
      [field]: value,
    };
    onUpdateAdjustment(id, updatedAdjustment); // Notify the parent component
  };

  const handleDayToggle = (id, day) => {
    const adjustment = basicInfo.time_based_adjustments[id];
    const updatedActiveDays = {
      ...adjustment.active_days,
      [day]: !adjustment.active_days[day], // Toggle the active state of the day
    };
    const updatedAdjustment = {
      ...adjustment,
      active_days: updatedActiveDays,
    };
    onUpdateAdjustment(id, updatedAdjustment); // Notify the parent component
  };

  const timeBasedAdjustments = basicInfo?.time_based_adjustments || {};

  return (
    <div>
      {timeBasedAdjustments && Object.keys(timeBasedAdjustments).length !== 0 && (
        <div className="adjustments-table-container">
          <h4>Time-Based Adjustments</h4>
          <table className="profile-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Degrees (°C)</th>
                <th>Time</th>
                <th>Active Days</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(timeBasedAdjustments).map(([id, adjustment]) => {
                const { isValid, error } = validateAdjustment(adjustment);
                const activeDays = adjustment.active_days || {};

                return (
                  <tr key={id} className={isValid ? "" : "disabled"}>
                    <td>{adjustment.name}</td>
                    <td>
                      <input
                        type="number"
                        value={adjustment.value}
                        onChange={(e) =>
                          handleFieldChange(id, "value", parseFloat(e.target.value))
                        }
                        min="-5"
                        max="-1"
                        step="1"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={adjustment.start_at}
                        onChange={(e) =>
                          handleFieldChange(id, "start_at", parseInt(e.target.value, 10))
                        }
                        style={{ width: "50px" }}
                        min="0"
                        max="23"
                        step="1"
                      />
                      {" - "}
                      <input
                        type="number"
                        value={adjustment.end_at}
                        onChange={(e) =>
                          handleFieldChange(id, "end_at", parseInt(e.target.value, 10))
                        }
                        style={{ width: "50px" }}
                        min="0"
                        max="23"
                        step="1"
                      />
                    </td>
                    <td>
                      {Object.entries(activeDays).map(([day, isActive]) => (
                        <label key={day} style={{ marginRight: "10px" }}>
                          <input
                            type="checkbox"
                            checked={isActive}
                            onChange={() => handleDayToggle(id, day)}
                          />
                          {day.substring(0, 3)}
                        </label>
                      ))}
                    </td>
                    <td>{isValid ? "" : `Warning: ${error}`}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}





export function ProfileTable  ({ profileConfigs, basicInfo, onTimeBasedAdjustmentToggle, onToggleEnabled, onToggleOptimisationEnabled, onReserveChange, onReserveHeatingAfternoonChange, onPriceChange, onPriceDropChange, onThermostatSettingChange, onThermostatReserveHeatingChange }) {
  //console.log (profileConfigs)
  const isIphone = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //console.log ("iPhone", isIphone)


  
  return (
    <div>
      {isIphone && ( 
        <div>
          <i>Temperature profiles can be edited only on desktop browsers.</i>
          <br />
          <i>You can still choose and save the scheme on mobile.</i>
        </div>
      )}
      <div className="profile-tables-container">
        <p></p>
        <h3>Electricity price based room temperature profiles</h3>
        {profileConfigs.map((profile) => (
          <div key={profile.id} className="profile-table">
            <h4>{profile.name}</h4>
            <label>
              Enabled:
              <input 
                type="checkbox" 
                checked={profile.enabled} 
                onChange={(e) => onToggleEnabled(profile.id, 'enabled', e.target.checked)} 
                disabled={true} 
              />
            </label>
            {profile.optimisation_enabled !== undefined ? (
              <div>
                <label>
                  HabitaIO Optimisation enabled:
                  <input 
                    type="checkbox" 
                    checked={profile.optimisation_enabled} 
                    onChange={(e) => onToggleOptimisationEnabled(profile.id,'optimisation_enabled', e.target.checked)}
                    disabled={true} 
                  />
                </label>
              </div>
            ) : null } 
            <table className="profile-table">
              <thead>
                <tr>
                  <th>Hourly electricity price ranges [c/kWh]</th>
                  {profile.price_ranges[0] && Object.keys(profile.price_ranges[0])
                    .filter(key => key !== 'id' && key !== 'range')
                    .map(scheme => (
                      <th key={scheme}>Target temperature {scheme.toUpperCase()} (°C)</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {profile.price_ranges.map((range, index) => (
                  <tr key={range.id}>
                    <td>{range.range}</td>
                    {Object.keys(range)
                      .filter(key => key !== 'id' && key !== 'range')
                      .map(scheme => (
                        <td key={scheme}>
                          {isIphone ? (
                            <span>{range[scheme]}</span>  // Display value as plain text on mobile
                          ) : (
                          <input 
                            type="number"
                            value={range[scheme]} 
                            onChange={(e) => onPriceChange(profile.id, range.id, scheme, e.target.value)}
                            min="7"
                            max="40"
                            step="0.5"
                          />
                          )}
                      </td>
                      ))}
                      
                  </tr>
                ))}
              </tbody>
              
            </table>
            
          
          {profile.time_based_adjustments !== undefined ? (
            <table className="profile-table">
              <thead>
                <tr>
                  <th>Time-Based Adjustments</th>
                  {/*Terrible hack here to present the checkbox for scheme_1, eg Home or Paikalla. To render all schemes use 'scheme.startsWith("scheme_")''  */}
                  {Object.keys(basicInfo.schemes ? basicInfo.schemes : {}).map((scheme, index) => (
                    scheme.startsWith("scheme_1") && (
                      <th key={index}>Enabled for {basicInfo.schemes[scheme].toUpperCase()}</th>
                    )
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(basicInfo.time_based_adjustments).map(([adjustmentId, adjustmentDetails]) => (
                  <tr key={adjustmentId}>
                    {/* Render adjustment name */}
                    <td>{adjustmentDetails.name}</td>
                    {/*Terrible hack here to present the checkbox for scheme_1, eg Home or Paikalla */}
                    {Object.keys(basicInfo.schemes).map((scheme) => (
                      scheme.startsWith("scheme_1") && (
                        <td key={`${adjustmentId}-${scheme}`}>
                          <input
                            type="checkbox"
                            checked={!!profile.time_based_adjustments?.[adjustmentId]?.[scheme]}
                            onChange={(e) =>
                              onTimeBasedAdjustmentToggle(
                                profile.id,
                                `time_based_adjustments.${adjustmentId}.${scheme}`,
                                e.target.checked
                              )
                            }
                          />
                        </td>
                      )
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : null}

            
          {profile.reserve_heating && profile.reserve_heating.hours !== undefined && profile.reserve_heating.reserve_degrees !== undefined ? (
            <table className="profile-table">
              
              <thead>
                <tr>
                  <th>Spot price based Reserve Heating</th>
                  <th>Hours</th>
                  <th>Degrees (°C)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Increase the target temperature during the lowest spot price <i>hours</i> by the <i>degrees</i> </td>
                  <td>
                    {isIphone ? (
                      <span>{profile.reserve_heating.hours}</span>  // Display value as plain text on mobile
                    ) : (
                      <input 
                        type="number"
                        value={profile.reserve_heating.hours} 
                        onChange={(e) =>
                          onReserveChange(profile.id, 'hours', e.target.value)  // Ensure value is passed as string, and parseFloat will handle it
                        }
                        min="0"
                        max="24"
                        step="1"
                      />
                    )}  
                  </td>
                  <td>
                    {isIphone ? (
                      <span>{profile.reserve_heating.reserve_degrees}</span>  // Display value as plain text on mobile
                    ) : (
                      <input 
                        type="number"  
                        value={profile.reserve_heating.reserve_degrees} 
                        onChange={(e) =>
                          onReserveChange(profile.id, 'reserve_degrees', e.target.value)  // Ensure correct value is passed
                        }
                        min="0"
                        max="10"
                        step="0.5"
                      />
                    )}  
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null }
          
          
        {profile.reserve_heating_afternoon && (
          <table className="profile-table">
            <thead>
                <tr>
                  <th>Spot price based Reserve Heating for Afternoons</th>
                  <th>Hours</th>
                  <th>Degrees (°C)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Between 12 - 17, Increase the target temperature during the lowest spot price <i>hours</i> by the <i>degrees</i> </td>
              <td>
              <input
                type="number"
                value={profile.reserve_heating_afternoon.hours}
                onChange={(e) =>
                  onReserveHeatingAfternoonChange(profile.id, 'reserve_heating_afternoon', 'hours', e.target.value)
                }
                min="0"
                max="24"
                step="1"
              />
            </td>
            <td>
              <input
                type="number"
                value={profile.reserve_heating_afternoon.reserve_degrees}
                onChange={(e) =>
                  onReserveHeatingAfternoonChange(profile.id, 'reserve_heating_afternoon', 'reserve_degrees', e.target.value)
                }
                min="0"
                max="10"
                step="0.5"
              />
            </td>
          </tr>
          </tbody>
          </table>
        )}


          {profile.price_based_drops && profile.price_based_drops.reference_price_name !== undefined ? (
            <table className="profile-table">
              
              <thead>
                <tr>
                  <th>Spot price based Temperature Adjustments</th>
                  
                  {Object.keys(profile.price_based_drops)
                    .filter(key => key !== 'reference_price_name')
                    .map(scheme => (
                      <th key={scheme}>Temperature change on {scheme.toUpperCase()} (°C)</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Change the target temperature when spot price is above <i>{profile.price_based_drops.reference_price_name}</i> by <i>Degrees (°C)</i></td>
                  {Object.entries(profile.price_based_drops)
                    .filter(([scheme]) => scheme !== 'reference_price_name') // Apply the same filter here
                    .map(([scheme, dropValue]) => (
                    <td key={scheme}>
                      {isIphone ? (
                        <span>{dropValue}</span>  // Display value as plain text on mobile
                      ) : (
                        <input 
                          type="number" 
                          value={dropValue} 
                          onChange={(e) => onPriceDropChange(profile.id, scheme, e.target.value)}
                          min="-5"
                          max="5"
                          step="0.5"
                        />
                      )}
                    </td>
                    ))}
                </tr>
              </tbody>
            </table>
                   
          ) : null}
          
          {/* Thermostat Settings */}
          {profile.thermostat_settings && (
            <div>
              <table className="profile-table">
                <thead>
                  <tr>
                    <th>Thermostat Specific Settings</th>
                    <th>Setting type</th>
                    <th>Degrees (°C)</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(profile.thermostat_settings).map(([key, setting]) => (
                    <tr key={key}>
                      <td>{setting.name}</td>
                      <td>
                        {setting.type === 'calibration' ? (
                          <>
                            <i>Calibration</i> - adjust the target temperature on the AHP by <i>degrees</i>
                          </>
                        ) : setting.type === 'fixed' ? (
                          <>
                            <i>Fixed</i> - adjust the target temperature on the heating device by <i>degrees</i>
                          </>
                        ) : null}
                      </td>
                        
                      <td>
                        {setting.type === 'fixed' ? (
                          // Input for fixed type
                          <input
                            type="number"
                            value={setting.value}
                            onChange={(e) =>
                              onThermostatSettingChange(profile.id, key, parseFloat(e.target.value))
                            }
                          min="-5"
                          max="5"
                          step="0.5"
                          />
                        ) : setting.type === 'calibration' ? (
                          // Input for calibration type
                          <input
                            type="number"
                            value={setting.value}
                            onChange={(e) =>
                              onThermostatSettingChange(profile.id, key, parseFloat(e.target.value))
                            }
                          min="-5"
                          max="5"
                          step="1"
                          />
                        ) : (
                          // Default input for other types
                          <input
                            type="text"
                            value={setting.value}
                            onChange={(e) =>
                              onThermostatSettingChange(profile.id, key, e.target.value)
                            }
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          
          
        {profile.thermostat_reserve_heating && (
          <div>
            <table className="profile-table">
              <thead>
                <tr>
                  <th>Thermostat Specific Reserve Heating</th>
                  <th>% of day max price</th>
                  <th>Hours</th>
                  <th>Degrees (°C)</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(profile.thermostat_reserve_heating).map(([key, thermostat]) => (
                  <tr key={key}>
                    {/* Using the key as the thermostat identifier */}
                    {thermostat.type === 'max_spot' ? (
                      <>
                        <td>{thermostat.name}</td>
                        <td>
                          {/* Input for limit type */}
                          <input
                            type="number"
                            value={thermostat.limit * 100} //  Convert to percentage
                            onChange={(e) =>
                              onThermostatReserveHeatingChange(profile.id, 'thermostat_reserve_heating', key, 'limit', parseFloat(e.target.value) / 100)
                            }
                            min="0"
                            max="50"
                            step="5"
                          />
                        </td>
                      </>
                    ) : (
                      <td colSpan="2">{thermostat.name}</td>
                    )}
                    <td>
                      <input
                        type="number"
                        value={thermostat.hours}
                        onChange={(e) =>
                          onThermostatReserveHeatingChange(profile.id, 'thermostat_reserve_heating', key, 'hours', parseFloat(e.target.value))
                        }
                        min="0"
                        max="24"
                        step="1"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={thermostat.reserve_degrees}
                        onChange={(e) =>
                          onThermostatReserveHeatingChange(profile.id, 'thermostat_reserve_heating', key, 'reserve_degrees', parseFloat(e.target.value))
                        }
                        min="0"
                        max="5"
                        step="1"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
          

          
          
            
          </div>
        ))}
      </div>
    </div>  
  );
};
