
import React, { useState, useEffect } from 'react';
import './App.css';
//import { useAuthenticator } from '@aws-amplify/ui-react';
import { signOut } from '@aws-amplify/auth';
//import Header from './Header'
import VentilationCharts from './VentilationCharts'
import HeatingCharts from './HeatingCharts'
import Settings from './Settings'
import HeatingSettings from './HeatingSettings'
import VentilationSettings from './VentilationSettings'
import { useUser } from './UserContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Optional for basic styling


function Main() {
  //const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { userData, jwtToken, loadingUser, showSettings } = useUser();
  
  const [logAndConfigData, setLogAndConfigData] = useState(null);
  const [logAndConfigError, setLogAndConfigError] = useState(null);
  const [configurationsData, setConfigurationsData] = useState(null);
  const [ventilationConfigs, setVentilationConfigs] = useState(null);
  const [heatingConfigs, setHeatingConfigs] = useState(null);
  const [loadConfigurations, setLoadConfigurations] = useState(true);
  const [configurationsError, setConfigurationsError] = useState(null);
  
  const [heatingData, setHeatingData] = useState(null);
  const [thermostatIds, setThermostatIds] = useState(null);
  const [heatingError, setHeatingError] = useState(null);
  const [heatingErrorState, setHeatingErrorState] = useState(null);
  
  const [ventilationData, setVentilationData] = useState(null);
  const [ventIds, setVentIds] = useState(null);
  const [ventilationError, setVentilationError] = useState(null);
  const [ventilationErrorState, setVentilationErrorState] = useState(null);
  
  const [loading, setLoading] = useState({ logAndConfig: null, ventilation: null, heating: null, configurations: null });
  const [error, setError] = useState(null);
  //const [errorState, setErrorState] = useState(null);
  
  
  const [currentDate, setCurrentDate] = useState(new Date()); // Default to last 24 hours if no date is set
  const [tabIndex, setTabIndex] = useState(0);

  const [saveStatus, setSaveStatus] = useState('');



useEffect(() => {
    if (userData && !loading.ventilation && !loading.heating && !loading.configurations) { // only fetchData if userData is in place and loading has not started already
      setLoading(prev => ({ ...prev, ventilation: true }));
      setLoading(prev => ({ ...prev, heating: true }));
      setLoading(prev => ({ ...prev, configurations: true }));
    // Reset error states before fetching new data
      setVentilationError(null);
      setVentilationErrorState(null);
      setHeatingError(null);
      setHeatingErrorState(null);

      fetchData(currentDate);
    }
  }, [userData, currentDate]);

const fetchData = async (date) => {
  //setLoadingData(true);  
  const dateToFetch = date
  if (!userData) {
    console.log("UserData is not available yet.");
    return; // Exit if userData is not available
   }
    
    console.log("Fetching datas")
    
    
    try {
      
      // Fetch all datas
      const logAndConfigResponse = await fetchLogAndConfigData (dateToFetch)
      console.log(logAndConfigResponse)

  
      // Handle configurations data (separately for heating and ventilation)
      if (logAndConfigResponse.configurationsdata) {
        const configData = logAndConfigResponse.configurationsdata;
        if (configData.statusCode === 200 && configData.body) {
          console.log("Configuration data fetched successfully.");
          const parsedConfig = JSON.parse(configData.body);
  
          if (parsedConfig.aircontrol_configs) {
            console.log("Processing ventilation configs");
            await setVentilationConfigs(parsedConfig.aircontrol_configs);
            console.log (ventilationConfigs)
          }
  
          if (parsedConfig.heatingcontrol_configs) {
            console.log("Processing heating configs");
            await setHeatingConfigs(parsedConfig.heatingcontrol_configs);
          }
        
          setLoadConfigurations(false);
        } else {
          console.error("Error fetching configuration data:", configData.statusCode);
          setConfigurationsError(`Error fetching configuration data: ${configData.statusCode}`);
        }
      }

      if (logAndConfigResponse.ventilationdata) {
        await processVentilationData(logAndConfigResponse.ventilationdata);
      }
      
      if (logAndConfigResponse.heatingcontroldata) {
        await processHeatingData(logAndConfigResponse.heatingcontroldata);
      }

  
    } catch (error) {
      setError(error.toString());
    }




    finally {
      
      setLoading(prev => ({ ...prev, ventilation: false }));
      setLoading(prev => ({ ...prev, heating: false }));
      setLoading(prev => ({ ...prev, configurations: false }));
      console.log ("Configs data:", configurationsData)
    }
};


const fetchLogAndConfigData = async (dateToFetch) => {
  const accountName = userData.accountName;
  const dateForAPI = dateToFetch.toISOString().split('T')[0]; // "YYYY-MM-DD"
  console.log("Fetching Logs and Configs data for:", accountName, dateForAPI);

  try {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${jwtToken}` 
      },
      body: JSON.stringify({
        account_name: accountName,
        date: dateForAPI,
        load_ventilation_data: true,
        load_heating_data: true,
        load_configuration_data: loadConfigurations
      }),
    };
    console.log(requestOptions)
    const response = await fetch('https://j1jjasmb97.execute-api.eu-north-1.amazonaws.com/LoadLogsAndConfigurations-API', requestOptions);

    if (!response.ok) {
      console.log("Response NOT OK", response.status);
      let logAndConfigErrorMessage = `Error ${response.status}: An error occurred.`; // Default error message
      
      console.log("Error message:", logAndConfigErrorMessage);
      setLogAndConfigError(logAndConfigErrorMessage);
      throw new Error(logAndConfigErrorMessage); // Halt execution and signal an error
    }
    else {
      setError(null)
    }
    
    const data = await response.json();  // Parse JSON outside of the error handling condition
    setLogAndConfigData(data)
    console.log("Logs and Configs fetched");
    return data;
  } catch (error) {
    setLogAndConfigError(error.message);
    console.error("Error fetching log and config data:", error);
  }
};


const processVentilationData = (ventilationDataResponse) => {
    console.log("processing ventilationDataResponse")
    const statusCode = ventilationDataResponse.statusCode
    console.log (statusCode)
        // Parse the body if it's a string

    if (statusCode !== 200) {
      console.log("Response NOT OK", statusCode);
      let ventilationErrorMessage = `Error ${statusCode}: An error occurred.`; // Default error message
      
      switch (statusCode) {
        case 411:
          console.log("Case 411")
          setVentilationErrorState('noHistoryData');
          ventilationErrorMessage = '411 no data: No historical data available for the selected date - try another date.';
          break;
        case 410:
          setVentilationErrorState('noService');
          ventilationErrorMessage = '410 no service: service not activated';
          break;
        default:
          setVentilationError('genericError');
          break;
      }
      
      console.log("Ventilation Error message:", ventilationErrorMessage);
      setVentilationError(ventilationErrorMessage);
      return;
      //throw new Error(ventilationErrorMessage); // Halt execution and signal an error
    }
    else {
      setError(null)
    }
    
    const responseBody = typeof ventilationDataResponse.body === 'string' 
                     ? JSON.parse(ventilationDataResponse.body) 
                     : ventilationDataResponse.body;


    console.log("VentilationData parsed");
    
    setVentilationData(responseBody);
    
    // Use ventilationDataResponse directly to derive ventIds
    const ventIds_temp =  responseBody && responseBody.metadata.ventilation_id_list 
                         ? responseBody.metadata.ventilation_id_list 
                         : [];
      setVentIds(ventIds_temp);
      
    console.log ("Vent Ids in fetchData:", ventIds_temp)  

};


const processHeatingData = (heatingDataResponse) => {
    console.log(heatingDataResponse)
    const statusCode = heatingDataResponse.statusCode
    

    if (statusCode !== 200) {
      console.log("Response NOT OK", statusCode);
      let heatingErrorMessage = `Error ${statusCode}: An error occurred.`; // Default error message
      
      switch (statusCode) {
        case 411:
          setHeatingErrorState('noHistoryData');
          heatingErrorMessage = '411 no data: No historical data available for the selected date - try another date.';
          break;
        case 410:
          setHeatingErrorState('noService');
          heatingErrorMessage = '410 no service: service not activated';
          break;
        default:
          setHeatingError('genericError');
          break;
      }
      
      console.log("Heating Error message:", heatingErrorMessage);
      setHeatingError(heatingErrorMessage);
      return ;
      //throw new Error(heatingErrorMessage); // Halt execution and signal an error
    }
    else {
      setError(null)
    }
    
            // Parse the body if it's a string
    const responseBody = typeof heatingDataResponse.body === 'string' 
                         ? JSON.parse(heatingDataResponse.body) 
                         : heatingDataResponse.body;
    
    setHeatingData(responseBody);
    const thermostatIds_temp = responseBody && responseBody.metadata.thermostat_id_list 
                       ? responseBody.metadata.thermostat_id_list 
                       : [];
    setThermostatIds(thermostatIds_temp);
    console.log ("Thermostat Ids in fetchData:", thermostatIds_temp)


    
    console.log("HeatingData processed");
    
};




  // Handlers for changing dates
  const goToPreviousDate = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 1);
      console.log("Prev date", newDate)
      return newDate;
    });
  };

  const goToNextDate = () => {
    console.log("Next date")
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 1);
      console.log("Next date", newDate)
      return newDate;
    });
  };

    const handleSignOut = async () => {
    try {
      await signOut(); // This will clear the authentication session
      window.location.reload(); // Optionally reload the page to show the login screen
 //     setError(null)
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };



  const handleSave = async (updatedConfig, configName) =>  {
    // API call to save updated configurations
    const accountName = userData.accountName;
    console.log("Saving settings:", accountName, configName);
    
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${jwtToken}` 
        },
        body: JSON.stringify({
          account_name: accountName,
          [configName]: updatedConfig
        }),
      };
  
      const response = await fetch('https://j1jjasmb97.execute-api.eu-north-1.amazonaws.com/SaveConfigs-API', requestOptions);

      if (!response.ok) {
        console.log("Response NOT OK", response.status);
        setSaveStatus('error');
      } else {
        const data = await response.json();  // Parse JSON outside of the error handling condition
        console.log("Config saved", data);
        setSaveStatus('success');
        if (configName === 'heatingcontrol_configs') {
          console.log("HeatingControl_configs updated")
          setHeatingConfigs(updatedConfig);
        
        } else if (configName === 'aircontrol_configs') {
          console.log("AirControl_configs updated")
          setVentilationConfigs(updatedConfig);
        }
         setLoadConfigurations(true);
      }
    } catch (error) {
      console.error("Error saving configs:", error);
      setSaveStatus('error');
    }

    // Clear the save status message after a few seconds
    setTimeout(() => {
      setSaveStatus('');
    }, 3000);    
  };





  
const handleTabSelect = (index) => {
    setTabIndex(index); // Update the current tab index when a new tab is selected
};
  

if (loadingUser) {
    return <div>Loading user data...</div>;
  }

if (error) {
    return <div>Error occured {error}</div>;
  }

//if (loading.heating || loading.ventilation || loading == null ) { // to prevent rendering before data is loaded
if (loading.heating || loading.ventilation || loading.configurations || loading.ventilation == null || loading.heating == null || loading.configurations == null ) { // to prevent rendering before data is loaded
    return  <div>Loading application data...</div>;
  }

  if (showSettings) {
    return (
      <>
        <Settings/>
      </>
    );
  }






// const ventIds = ventilationData && ventilationData.vent_id_list ? ventilationData.vent_id_list : [];
console.log("VentIds before rendering: ", ventIds);
console.log("HeatingIds before rendering: ", thermostatIds);
console.log("HeatingConfigs passed to Paramaters: ", heatingConfigs);
console.log("VentilationConfigs passed to Paramaters: ", ventilationConfigs);
console.log("Current Date:", currentDate)
//  <h1>Hello {userData.accountName}</h1>
return (
<>
  <p></p>
  <div className="chart-container">
    <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
        <TabList>
            {ventilationErrorState !== 'noService' && (
              <Tab><strong>Air controls</strong></Tab>
            )}
            {heatingErrorState !== 'noService' && (
              <Tab><strong>Energy controls</strong></Tab>
            )}
            {ventilationConfigs && (
              <Tab><strong>Air parameters</strong></Tab>
            )}  
            {heatingConfigs && (
              <Tab><strong>Energy parameters</strong></Tab>
            )}
        </TabList>

        {ventilationErrorState !== 'noService' && (
          <TabPanel>
              {ventilationError ? (
                  <div>
                      {ventilationErrorState === 'noHistoryData' && (
                          <>
                              <div>{ventilationError} <button onClick={goToPreviousDate}>Previous Day</button> <button onClick={goToNextDate}>Next Day</button></div>
                          </>
                      )}
                      {ventilationErrorState === 'genericError' && (
                          <>
                              <div>{ventilationError}</div>
                          </>
                      )}
                  </div>
              ) : (
                  <VentilationCharts
                      currentDate={currentDate}
                      onPreviousDate={goToPreviousDate}
                      onNextDate={goToNextDate}
                      ventilationData={ventilationData}
                      ventIds={ventIds}
                  />
              )}
          </TabPanel>
        )}      
        {heatingErrorState !== 'noService' && (
          <TabPanel>
              {heatingError ? (
                <div>
                    {heatingErrorState === 'noHistoryData' && (
                        <>
                            <div>{heatingError} <button onClick={goToPreviousDate}>Previous Day</button> <button onClick={goToNextDate}>Next Day</button></div>
                        </>
                    )}
                    {heatingErrorState === 'genericError' && (
                        <>
                            <div>{heatingError}</div>
                        </>
                    )}
                </div>
            ) : (
            <HeatingCharts                 
              currentDate={currentDate}
              onPreviousDate={goToPreviousDate}
              onNextDate={goToNextDate}
              data={heatingData} />
            )}    
          </TabPanel>
        )}  
        {ventilationConfigs && (
          <TabPanel>
            <VentilationSettings
              initialVentilationConfigs={ventilationConfigs}
              onSave={(config) => handleSave(config, 'aircontrol_configs')}
              saveStatus={saveStatus}
  
            />
          </TabPanel>
        )}
        {heatingConfigs && (
          <TabPanel>
            <HeatingSettings
              initialHeatingConfigs={heatingConfigs}
              onSave={(config) => handleSave(config, 'heatingcontrol_configs')}
              saveStatus={saveStatus}
            />
          </TabPanel>
        )}  
    </Tabs>
</div>

</>
);
}
export default Main;

//          <HeatingSettings
//            configurations={configurationsData}
//          />