import React, { useState } from 'react';
import NumericInput from './NumericInput.js'
import './VentilationSettings.css';


const VentilationSettings = ({ initialVentilationConfigs, onSave, saveStatus }) => {
  //const [configs, setConfigs] = useState(ventilationConfig);
  const [ventilationConfig, setVentilationConfig] = useState(initialVentilationConfigs);
  const isIphone = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

//console.log ("Initial configs", initialVentilationConfigs)
if (!ventilationConfig || Object.keys(ventilationConfig).length === 0) {
    return <div>Loading configuration data...</div>;
}



const handleInputChange = (controlId, stateName, key, value) => {
    setVentilationConfig((prevConfigs) => {
        const updatedConfigs = prevConfigs.map((control) => {
            if (control.id === controlId) {
                if (key === 'enabled') {
                    return {
                        ...control,
                        enabled: value,
                    };
                }

                if (stateName) {
                    const updatedStates = control.states.map((state) => {
                        if (state.name === stateName) {
                            const keys = key.split('.');

                            // Handling outdoor_triggers
                            if (keys[0].startsWith('outdoor_triggers')) {
                                const match = keys[0].match(/\[(\d+)\]/);
                                if (!match) {
                                    console.error("Unable to parse trigger index from key:", key);
                                    return state; // Skip if there's an issue
                                }

                                const triggerIndex = parseInt(match[1], 10); // Extract index from 'outdoor_triggers[0]'
                                const triggerKey = keys[2]; // This will be 'co2' or 'humidity'

                                // Convert co2 or humidity to number
                                const numericValue = triggerKey === 'co2' || triggerKey === 'humidity'
                                    ? parseFloat(value) : value;

                                const updatedTriggers = state.outdoor_triggers.map((trigger, index) => {
                                    if (index === triggerIndex) {
                                        if (!trigger.triggers) {
                                            console.error("No triggers found for:", trigger);
                                            return trigger;
                                        }

                                        return {
                                            ...trigger,
                                            triggers: {
                                                ...trigger.triggers,
                                                [triggerKey]: numericValue,
                                            },
                                        };
                                    }
                                    return trigger;
                                });

                                return {
                                    ...state,
                                    outdoor_triggers: updatedTriggers,
                                };
                            } 

                            // Handling default_triggers
                            if (keys[0] === 'default_triggers') {
                                const triggerKey = keys[1]; // This will be 'co2' or 'humidity'
                                const numericValue = triggerKey === 'co2' || triggerKey === 'humidity'
                                    ? parseFloat(value) : value;

                                return {
                                    ...state,
                                    default_triggers: {
                                        ...state.default_triggers,
                                        [triggerKey]: numericValue,
                                    },
                                };
                            }

                            // Handling hysteresis
                            if (keys[0] === 'hysteresis') {
                                const hysteresisKey = keys[1]; // This will be 'co2' or 'humidity'
                                const numericValue = hysteresisKey === 'co2' || hysteresisKey === 'humidity'
                                    ? parseFloat(value) : value;

                                return {
                                    ...state,
                                    hysteresis: {
                                        ...state.hysteresis,
                                        [hysteresisKey]: numericValue,
                                    },
                                };
                            }

                            // General handling of nested objects
                            const [outerKey, innerKey, field] = keys;
                            const numericValue = (field === 'co2' || field === 'humidity')
                                ? parseFloat(value)
                                : value;

                            if (outerKey && innerKey && field) {
                                return {
                                    ...state,
                                    [outerKey]: {
                                        ...state[outerKey],
                                        [innerKey]: {
                                            ...state[outerKey][innerKey],
                                            [field]: numericValue,
                                        },
                                    },
                                };
                            } else if (outerKey && innerKey) {
                                return {
                                    ...state,
                                    [outerKey]: {
                                        ...state[outerKey],
                                        [innerKey]: numericValue,
                                    },
                                };
                            }

                            return state; // In case nothing matches
                        }
                        return state;
                    });

                    return {
                        ...control,
                        states: updatedStates,
                    };
                }

                return control;
            }
            return control;
        });

        return updatedConfigs;
    });
};




   const handleSave = () => {
    // Send the updated ventilationConfig to the parent component
    onSave(ventilationConfig);
  };


  return (
    <div className="container">
      <h2>Ventilation Settings</h2>
      {ventilationConfig.map((control) => (
        <div key={control.id} className="control-section">
          <h3>{control.name}</h3>
          <label className="control-label">
            Enabled:
            <input 
              type="checkbox" 
              checked={control.enabled} 
              onChange={(e) => handleInputChange(control.id, null, 'enabled', e.target.checked)} 
            />
          </label>
          
          {control.states.map((state) => (
            <div key={state.name}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Trigger level for</th>
                    <th>{state.name}</th>
                    <th></th>
                  </tr>
                </thead>  
                <tbody>
                  <tr>
                    {state.outdoor_triggers && (
                      <>
                        <td>Outdoor Temperature (Celcius)</td>
                        {state.outdoor_triggers[0].triggers.co2 !== undefined && (
                          <td>CO2 exceeds (ppm)</td>
                        )}
                        {state.outdoor_triggers[0].triggers.humidity !== undefined && (
                          <td>Humidity exceeds (%)</td>
                        )}
                      </>
                    )}
                    {state.default_triggers && (
                      <>
                        <td></td>
                        {state.default_triggers.co2 !== undefined && (
                          <td>CO2 exceeds (ppm)</td>
                        )}
                        {state.default_triggers.humidity !== undefined && state.condition !== 'max' && (
                          <td>Humidity exceeds (%)</td>
                        )}
                        {state.default_triggers.humidity !== undefined && state.condition === 'max' &&(
                          <td>Humidity below (%)</td>
                        )}
                      </>
                    )}
                  </tr>
                  
                  {state.hysteresis && (
                    <>
                      <td></td>
                      {state.hysteresis.co2 !== undefined && (
                        <td>CO2 (ppm)</td>
                      )}
                      {state.hysteresis.humidity !== undefined && (
                        <td>Humidity (%)</td>
                      )}
                    </>
                  )}

                  {/* Render Outdoor Triggers */}
                  {state.outdoor_triggers && state.outdoor_triggers.map((trigger, index) => (
                    <tr key={index}>
                      <td>{trigger.range}</td>
                      <td>
                          {isIphone ? (
                            <span>{trigger.triggers.co2}</span>  // Display value as plain text on mobile
                          ) : (
                          <input 
                            type="number"
                            value={trigger.triggers.co2} 
                            onChange={(e) => handleInputChange(control.id, state.name, `outdoor_triggers[${index}].triggers.co2`, e.target.value)}
                            step="25"
                            min="400"
                            max="2000"
                          />
                          )}
                      </td>
                      <td>
                          {isIphone ? (
                            <span>{trigger.triggers.humidity}</span>  // Display value as plain text on mobile
                          ) : (
                          <input 
                            type="number"
                          value={trigger.triggers.humidity} 
                          onChange={(e) => handleInputChange(control.id, state.name, `outdoor_triggers[${index}].triggers.humidity`, e.target.value)}
                          step="5"
                          max="95"
                          min="5"
                        />
                        )}
                      </td>
                    </tr>
                  ))}
                  {/* Render Default Triggers */}
                  {state.default_triggers && (
                    <tr>
                      <td>Trigger value</td>
                      {state.default_triggers.co2 !== undefined && (
                        <td>
                          {isIphone ? (
                            <span>{state.default_triggers.co2}</span>  // Display value as plain text on mobile
                          ) : (
                          <input 
                            type="number"
                            value={state.default_triggers.co2} 
                            onChange={(e) => handleInputChange(control.id, state.name, `default_triggers.co2`, e.target.value)}
                            step="25"
                            min="400"
                            max="2000"
                          />
                          )}
                        </td>
                      )}
                      {state.default_triggers.humidity !== undefined && (
                        <td>
                          {isIphone ? (
                            <span>{state.default_triggers.humidity}</span>  // Display value as plain text on mobile
                          ) : (
                          <input 
                            type="number"
                            value={state.default_triggers.humidity} 
                            onChange={(e) => handleInputChange(control.id, state.name, `default_triggers.humidity`, e.target.value)}
                            step="5"
                            max="95"
                            min="5"
                          />
                          )}
                        </td>
                      )}
                    </tr>
                  )}
                  
                  {/* Render hysteresis based triggers */}
                  {state.hysteresis && (
                    <tr>
                      <td>Switch back difference</td>
                      {state.hysteresis.co2 !== undefined && (
                        <td>
                          {isIphone ? (
                            <span>{state.hysteresis.co2}</span>  // Display value as plain text on mobile
                          ) : (
                          <input 
                            type="number"
                            value={state.hysteresis.co2} 
                            onChange={(e) => handleInputChange(control.id, state.name, `hysteresis.co2`, e.target.value)} 
                            step="25"
                            min="-200"
                            max="-50"
                          />
                          )}
                        </td>
                      )}
                      {state.hysteresis.humidity !== undefined && (
                        <td>
                          {isIphone ? (
                            <span>{state.hysteresis.humidity}</span>  // Display value as plain text on mobile
                          ) : (
                          <input 
                            type="number"
                            value={state.hysteresis.humidity} 
                            onChange={(e) => handleInputChange(control.id, state.name, `hysteresis.humidity`, e.target.value)} 
                            step="5"
                            min="-20"
                            max="20"
                          />
                          )}
                        </td>
                        
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      ))}
      

      {saveStatus === 'success' && <p><i>Configurations updated successfully!</i></p>}
      {saveStatus === 'error' && <p>Error updating configurations. Please try again.</p>}
      <button onClick={handleSave}>Save Settings</button>

    </div>
  );
};

export default VentilationSettings;
//      <div><a href="/ParameterGuide" target="_blank" rel="noopener noreferrer">
//        Read here about configuring your AirControl and default values.
//      </a><p></p></div>
